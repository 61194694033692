<template>
  <section>
    <router-view v-if="menuFunctions.CREATE_PESANAN"></router-view>
    <dont-have-permission-page v-else/>
  </section>
</template>

<script>
export default {
  name: 'order',
};
</script>
